import { ref } from "vue";

export default function(tabSize = 4) {
  const textArea = ref();
  const handleKeyTab = () => {
    if (textArea.value) {
      const start = textArea.value.selectionStart;
      const end = textArea.value.selectionEnd;
      const textValue = textArea.value.value;

      textArea.value.value =
        textValue.substring(0, start) +
        " ".repeat(tabSize) +
        textValue.substring(end);

      textArea.value.selectionStart = textArea.value.selectionEnd =
        start + tabSize;
    }
  };
  return { textArea, handleKeyTab };
}
