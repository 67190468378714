<template>
  <div class="w-full p-2">
    <el-form label-width="100px">
      <el-form-item label="配置项名称">
        <el-input v-model="name"></el-input>
      </el-form-item>
      <el-form-item label="配置项键名">
        <el-input v-model="key"></el-input>
      </el-form-item>
      <el-form-item label="配置项内容">
        <textarea
          v-model="value"
          @keydown.tab.prevent="handleKeyTab"
          ref="valueText"
          rows="10"
          class="w-full p-2 text-sm border border-gray-300 focus:outline-none focus:border-blue-300"
        >
        </textarea>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSave">保存</el-button>
        <el-button @click="format">格式化</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElNotification } from "element-plus";
import useTextareaTabToSpace from "@/composition/useTextareaTabToSpace";
import { useStore } from "vuex";

export default defineComponent({
  name: "SystemConfigEdit",
  setup() {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const name = ref("");
    const key = ref("");
    const value = ref("");
    const isEdit = route.params.uid !== "new";
    onMounted(async () => {
      if (isEdit) {
        const res = await store.dispatch("config/get", route.params.uid);
        name.value = res.data.name;
        key.value = res.data.key;
        value.value = JSON.stringify(res.data.value, null, 2);
      }
    });
    const { textArea: valueText, handleKeyTab } = useTextareaTabToSpace(4);
    const _checkForm = () => {
      if (!name.value || !name.value.length) {
        throw "配置项名称不能为空";
      }
      if (!key.value || !key.value.length) {
        throw "配置项键名不能为空";
      }
      if (!value.value || !value.value.length) {
        throw "配置项内容不能为空";
      }
    };
    const createConfig = () => {
      store
        .dispatch("config/create", {
          name: name.value,
          key: key.value,
          value: JSON.parse(value.value),
        })
        .then(() => {
          ElNotification.success({ message: "新增成功" });
          router.go(-1);
        })
        .catch((message) => ElNotification.error({ message }));
    };
    const editConfig = () => {
      store
        .dispatch("config/update", {
          uid: route.params.uid,
          patch: {
            name: name.value,
            key: key.value,
            value: JSON.parse(value.value),
          },
        })
        .then(() => {
          ElNotification.success({ message: "保持成功" });
          router.go(-1);
        })
        .catch((message) => ElNotification.error({ message }));
    };
    const onSave = () => {
      // TODO: 做config.name唯一性检测
      try {
        _checkForm();
      } catch (message) {
        ElNotification.error({ message });
        return;
      }

      if (isEdit) {
        editConfig();
      } else {
        createConfig();
      }
    };
    const format = () => {
      try {
        const data = JSON.parse(value.value);
        value.value = JSON.stringify(data, null, 2);
      } catch {
        ElNotification.error({ message: "数据格式有误" });
      }
    };
    return { name, key, value, onSave, handleKeyTab, valueText, format };
  },
});
</script>
